
.containerModal {
    border-radius: 25px;
}
.headerModal {
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 10px;
  padding-top: 10px;
  background-color: #f5f5f5;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.titleModal {
  font-weight: 400;
}

.contentModal {
  padding: 10px;
  max-height: 600px;
  overflow: auto;
}

.form {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}

.clearMarginBottom {
  margin-bottom: 0px;
}

.radioGroup {
  display: flex;
  flex: 1;
  margin-bottom: 10px;
}
.radioItem {
  flex: 1;
  text-align: center;
}
.containerBottom {
    display: flex;
    margin-top: 10px;
}
.buttonBottom {
    flex: 1
}
.hr{
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #BFBFBF;
    margin: 1em 0;
    padding: 0;
    margin-top: 0px;
}
