.footer {
    margin: 0;
    display: flex;
    padding: 30px;
    align-items: center;
    align-items: center;
    height: $footer-height;
    border-top: 1px solid $border-color;    

    span {
        margin-left: 10px;
    }

    @include screen-mobile {
        justify-content: center;
        flex-direction: column;
    }
}
